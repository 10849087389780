import React, { createContext, useContext, useEffect, useState } from 'react'

export enum Theme {
  LIGHT = 'light',
  AUTO = 'auto',
  DARK = 'dark'
}

type ThemeSetting = Theme | null
type SystemTheme = Theme.LIGHT | Theme.DARK | null
export type EffectiveTheme = Theme.LIGHT | Theme.DARK | null

type ThemeContextValue = {
  setThemeSetting: (theme: Theme) => void
  themeSetting: ThemeSetting
  systemTheme: SystemTheme
  effectiveTheme: EffectiveTheme
}

const DEFAULTS: ThemeContextValue = {
  themeSetting: null,
  systemTheme: null,
  effectiveTheme: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setThemeSetting: (theme: Theme) => null,
}

export const STORAGE_KEY = 'theme'

export const ThemeContext = createContext(DEFAULTS)
export const useTheme = (): ThemeContextValue => useContext(ThemeContext)
const getPrefersDarkQuery = (): MediaQueryList => window.matchMedia('(prefers-color-scheme: dark)')
const getEffectiveTheme = (setting: ThemeSetting, system: SystemTheme) => (setting == Theme.AUTO ? system : setting)
const applyThemeClass = (effectiveTheme: EffectiveTheme) => {
  if (!effectiveTheme) {
    return
  }

  // <https://paco.sh/blog/disable-theme-transitions>
  const css = document.createElement('style')
  css.type = 'text/css'
  css.appendChild(
    document.createTextNode(
      `* {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        -ms-transition: none !important;
        transition: none !important;
      }`
    )
  )
  document.head.appendChild(css)

  Object.values(Theme).forEach((item: Theme) => document.documentElement.classList.remove(item))
  document.documentElement.classList.add(effectiveTheme)

  // <https://paco.sh/blog/disable-theme-transitions>
  // Calling getComputedStyle forces the browser to redraw
  window.getComputedStyle(css).opacity
  document.head.removeChild(css)
}
export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [themeSetting, setThemeSetting] = useState<ThemeSetting>(DEFAULTS.themeSetting)
  const [systemTheme, setSystemTheme] = useState<SystemTheme>(DEFAULTS.systemTheme)
  const [effectiveTheme, setEffectiveTheme] = useState<EffectiveTheme>(DEFAULTS.effectiveTheme)

  const saveThemeSetting = (themeSetting: Theme) => {
    setThemeSetting(themeSetting)
    localStorage[STORAGE_KEY] = themeSetting
  }

  const updateThemeFromBrowser = () => setSystemTheme(getPrefersDarkQuery().matches ? Theme.DARK : Theme.LIGHT)

  useEffect(() => {
    // Listen to system theme changes (time-based or manual).
    const query = getPrefersDarkQuery()
    query.addEventListener('change', updateThemeFromBrowser)
    return () => query.removeEventListener('change', updateThemeFromBrowser)
  }, [])

  useEffect(() => {
    // Load theme setting.
    setThemeSetting((localStorage[STORAGE_KEY] as Theme) || Theme.DARK)
    updateThemeFromBrowser()
  }, [])

  useEffect(
    // Propagate setting/system theme change.
    () => setEffectiveTheme(getEffectiveTheme(themeSetting, systemTheme)),
    [themeSetting, systemTheme]
  )

  useEffect(
    // Propagate effective theme change
    () => applyThemeClass(effectiveTheme),
    [effectiveTheme]
  )

  return (
    <ThemeContext.Provider value={{ systemTheme, effectiveTheme, themeSetting, setThemeSetting: saveThemeSetting }}>
      {children}
    </ThemeContext.Provider>
  )
}

export const ThemeFoucScript = () => {
  /**
   * To prevent FOUC:
   * <https://tailwindcss.com/docs/dark-mode#toggling-dark-mode-manually>
   *
   * To prevent initial theme transitions:
   * <https://paco.sh/blog/disable-theme-transitions>
   */
  // language=JavaScript
  const __EMBEDDED_JAVASCRIPT_CODE__ = `
      const css = document.createElement('style')
      css.type = 'text/css'
      css.appendChild(
        document.createTextNode(
          \`* {
            -webkit-transition: none !important;
            -moz-transition: none !important;
            -o-transition: none !important;
            -ms-transition: none !important;
            transition: none !important;
          }\`
        )
      )
      document.head.appendChild(css)

      function setLightTheme() { document.documentElement.classList.add('${Theme.LIGHT}') }
      function setDarkTheme() { document.documentElement.classList.add('${Theme.DARK}') }

      switch(localStorage['${STORAGE_KEY}']) {
        case '${Theme.LIGHT}':
          setLightTheme()
        case '${Theme.DARK}':
          setDarkTheme()
        case '${Theme.AUTO}':
          if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            setDarkTheme()
          } else {
            setLightTheme()
          }
        default:
          setDarkTheme()
      }

      // Calling getComputedStyle forces the browser to redraw
      const _ = window.getComputedStyle(css).opacity
      document.head.removeChild(css)
    `
  return <script dangerouslySetInnerHTML={{ __html: __EMBEDDED_JAVASCRIPT_CODE__ }} />
}
