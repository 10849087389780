import React, { createContext, ReactNode, useEffect, useState } from 'react'

import tailwindConfig from '~/tailwind.config.js'

import { Breakpoint, BREAKPOINTS } from './types'

interface ContextType {
  currentBreakpoint: Breakpoint
}

export const ViewportContext = createContext<ContextType>({
  currentBreakpoint: 'xl',
})

export const ViewportProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoint>('xl')

  useEffect(() => {
    /**
     * Checking breakpoints.
     */
    const mediaQueries: Record<Breakpoint, MediaQueryList | null> = {
      sm: window.matchMedia('(min-width: 0)'),
      md: window.matchMedia(`(min-width: ${tailwindConfig.theme.screens.md})`),
      lg: window.matchMedia(`(min-width: ${tailwindConfig.theme.screens.lg})`),
      xl: window.matchMedia(`(min-width: ${tailwindConfig.theme.screens.xl})`),
    }

    // Decide on which breakpoint to set
    const handleQueryListener = () => {
      const matchedBreakpointName = [...BREAKPOINTS].reverse().find((breakpoint) => mediaQueries[breakpoint]?.matches)
      matchedBreakpointName && setCurrentBreakpoint(matchedBreakpointName)
    }
    handleQueryListener()

    BREAKPOINTS.forEach((breakpoint) => mediaQueries[breakpoint]?.addEventListener('change', handleQueryListener))

    /**
     * Cleanup phase.
     */
    return () => {
      BREAKPOINTS.forEach((breakpoint) => mediaQueries[breakpoint]?.removeEventListener('change', handleQueryListener))
    }
  }, [])

  return <ViewportContext.Provider value={{ currentBreakpoint }}>{children}</ViewportContext.Provider>
}

export default ViewportProvider
