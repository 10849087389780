import { Auth0Provider } from '@auth0/auth0-react'

import { PAGE_ROUTES } from '~/constants'
import { isProdEnv } from '~/lib/env'
import { FCWithChildren } from '~/lib/types'

// TODO: move config to env.
// NOTE: duplicated in pie-app
const PROD_CONFIG = { domain: 'httpie.us.auth0.com', clientId: 'fs179WVDOH9cWw3ZPx04OSiPDMhXOUDG' }
const DEV_CONFIG = { domain: 'httpie-dev.us.auth0.com', clientId: 'cXfzoBsG8vC84RjDi2YWFJaLfUE7gmAT' }
const config = isProdEnv() ? PROD_CONFIG : DEV_CONFIG

export const ConfiguredAuth0Provider: FCWithChildren = ({ children }) => (
  <Auth0Provider
    {...config}
    audience='pie-app'
    cacheLocation='localstorage'
    useRefreshTokens
  >
    {children}
  </Auth0Provider>
)

export const getRedirectUri = () => {
  const env = process.env.NEXT_PUBLIC_VERCEL_ENV
  // using "http" scheme for localhost redirect urls.
  const scheme = env ? 'https' : 'http'
  return `${scheme}://${window.location.host}${PAGE_ROUTES.APP}/auth-callback/`
}
