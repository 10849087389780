import type { AppProps } from 'next/app'

import '~/styles/globals.css'
import ViewportProvider from '~/lib/viewport'
import ContentLoadProvider from '~/lib/content'
import { useSmoothScrollPolyfill } from '~/lib/useSmoothScrollPolyfill'
import SWRFetcherProvider from '~/lib/swr/SWRFetcherProvider'
import { ThemeProvider } from '~/lib/ThemeProvider'
import { ConfiguredAuth0Provider } from '~/lib/auth0'

import 'tailwindcss/tailwind.css'

const MyApp = ({ Component, pageProps }: AppProps) => {
  useSmoothScrollPolyfill()

  return (
    <ConfiguredAuth0Provider>
      <ContentLoadProvider>
        <ViewportProvider>
          <SWRFetcherProvider>
            <ThemeProvider>
              <Component {...pageProps} />
            </ThemeProvider>
          </SWRFetcherProvider>
        </ViewportProvider>
      </ContentLoadProvider>
    </ConfiguredAuth0Provider>
  )
}

export default MyApp
