const { fontWeight } = require('tailwindcss/defaultTheme')

module.exports = {
  mode: 'jit',
  content: [
    './sections/**/*.{js,ts,jsx,tsx,css,sass}',
    './components/**/*.{js,ts,jsx,tsx,css,sass}',
    './components/**/*.stories.{js,ts,jsx,tsx}',
    './pages/**/*.{js,ts,jsx,tsx,css,sass}',
    './lib/**/*.{js,ts,jsx,tsx,css,sass}',
  ],
  darkMode: 'class',
  plugins: [require('@tailwindcss/forms')],
  theme: {
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
    },
    fontFamily: {
      headline: ['Pie Headline', 'Impact'],
      sans: ['Pie Body', 'sans-serif'],
      mono: ['Pie Monospace', 'monospace'],
    },
    fontWeight: {
      ...fontWeight,
      slim: 300,
      neutral: 400,
      median: 600,
    },
    boxShadow: (theme) => ({
      'green': `0 0 0 1px ${theme('colors.green')}`,
    }),
    letterSpacing: {
      headline: '0.012em',
      base: '0.011em',
    },
    extend: {
      fontSize: {
        15: '15px',
        14: '14px',
      },
      lineHeight: {
        'headline': '0.8em',
        'tight': '1.2em',
        '1-3': '1.3',
        'loose': '1.4em',
        '20': '20px',
      },
      backgroundImage: {
        'cli-background-dark': 'url("/Images/cli-background-dark.svg")',
        'cli-background-light': 'url("/Images/cli-background-light.svg")',
        'cli-background-desktop-dark': 'url("/Images/cli-background-desktop-dark.svg")',
        'cli-background-desktop-light': 'url("/Images/cli-background-desktop-light.svg")',
      },
      backgroundPosition: {
        'position-cli-background-desktop': '-1400px 50%',
      },
      gridTemplateColumns: {
        22: 'repeat(22, minmax(0, 1fr))',
      },
      gridColumnStart: {
        13: '13',
        14: '14',
        15: '15',
        16: '16',
        17: '17',
        18: '18',
        19: '19',
        20: '20',
        21: '21',
        22: '22',
        23: '23',
      },
      gridColumnEnd: {
        13: '13',
        14: '14',
        15: '15',
        16: '16',
        17: '17',
        18: '18',
        19: '19',
        20: '20',
        21: '21',
        22: '22',
        23: '23',
      },
      keyframes: {
        'ping-slow': {
          '60%, 100%': { transform: 'scale(1.5)', opacity: 0 },
        },
        fadeInOut: {
          '0%, 100%': { opacity: '0' },
          '20%, 90%': { opacity: '1' },
        },
        fadeIn: {
          '0%': { opacity: '0 ' },
          '100%': { opacity: '1' },
        },
      },
      animation: {
        'spin-slow': 'spin 3s linear infinite',
        'ping-slow': 'ping-slow 2s cubic-bezier(0.15, 0.27, 0.58, 1) infinite',
        fadeInOut: 'fadeInOut 1.85s forwards ease',
        fadeIn: 'fadeIn 0.15s forwards ease',
      },
    },
    /*
    DO NOT EDIT COLORS, SEE <https://github.com/httpie/pie-brand-palette>
    */
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      white: '#F5F5F0',
      black: '#1E1919',
      grey: {
        50: '#F5F5F0',
        100: '#EDEDEB',
        200: '#D1D1CF',
        300: '#B5B5B2',
        400: '#999999',
        500: '#7D7D7D',
        600: '#666663',
        700: '#4F4D4D',
        800: '#363636',
        900: '#1E1919',
        DEFAULT: '#7D7D7D',
      },
      aqua: {
        50: '#E8F0F5',
        100: '#D6E3ED',
        200: '#C4D9E5',
        300: '#B0CCDE',
        400: '#9EBFD6',
        500: '#8CB4CD',
        600: '#7A9EB5',
        700: '#698799',
        800: '#597082',
        900: '#455966',
        DEFAULT: '#8CB4CD',
      },
      purple: {
        50: '#F0E0FC',
        100: '#E3C7FA',
        200: '#D9ADF7',
        300: '#CC96F5',
        400: '#BF7DF2',
        500: '#B464F0',
        600: '#9E54D6',
        700: '#8745BA',
        800: '#70389E',
        900: '#5C2982',
        DEFAULT: '#B464F0',
      },
      orange: {
        50: '#FFEDDB',
        100: '#FFDEBF',
        200: '#FFCFA3',
        300: '#FFBF87',
        400: '#FFB06B',
        500: '#FFA24E',
        600: '#F2913D',
        700: '#E3822B',
        800: '#D6701C',
        900: '#C75E0A',
        DEFAULT: '#FFA24E',
      },
      red: {
        50: '#FFE0DE',
        100: '#FFC7C4',
        200: '#FFB0AB',
        300: '#FF968F',
        400: '#FF8075',
        500: '#FF665B',
        600: '#E34F45',
        700: '#C7382E',
        800: '#AD2117',
        900: '#910A00',
        DEFAULT: '#FF665B',
      },
      blue: {
        50: '#DBE3FA',
        100: '#BFCFF5',
        200: '#A1B8F2',
        300: '#85A3ED',
        400: '#698FEB',
        500: '#4B78E6',
        600: '#426BD1',
        700: '#3B5EBA',
        800: '#3354A6',
        900: '#2B478F',
        DEFAULT: '#4B78E6',
      },
      pink: {
        50: '#FFEBFF',
        100: '#FCDBFC',
        200: '#FCCCFC',
        300: '#FCBAFC',
        400: '#FAABFA',
        500: '#FA9BFA',
        600: '#DE85DE',
        700: '#C26EC2',
        800: '#A854A6',
        900: '#8C3D8A',
        DEFAULT: '#FA9BFA',
      },
      green: {
        50: '#E3F7E8',
        100: '#CCF2D6',
        200: '#B5EDC4',
        300: '#A1E8B0',
        400: '#8AE09E',
        500: '#73DC8C',
        600: '#63C27A',
        700: '#52AB66',
        800: '#429154',
        900: '#307842',
        DEFAULT: '#73DC8C',
      },
      yellow: {
        50: '#F7F7DB',
        100: '#F2F2BF',
        200: '#EDEDA6',
        300: '#E5E88A',
        400: '#E0E36E',
        500: '#DBDE52',
        600: '#CCCC3D',
        700: '#BABA29',
        800: '#ABA614',
        900: '#999400',
        DEFAULT: '#DBDE52',
      },
    },
  },
}
