import { createContext, useState, useEffect } from 'react'

import { FCWithChildren } from '~/lib/types'

interface ContextType {
  contentLoaded: boolean
}

export const contentLoadContext = createContext<ContextType>({ contentLoaded: false })

export const ContentLoadProvider: FCWithChildren = ({ children }) => {
  const [contentLoaded, setContentLoaded] = useState<boolean>(false)

  useEffect(() => {
    const handleContentLoaded = () => setContentLoaded(true)
    window.addEventListener('load', handleContentLoaded)

    return () => window.removeEventListener('load', handleContentLoaded)
  }, [])

  return <contentLoadContext.Provider value={{ contentLoaded }}>{children}</contentLoadContext.Provider>
}

export default ContentLoadProvider
