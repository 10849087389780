export enum PAGE_ROUTES {
  ABOUT = '/about',
  APP = '/app',
  BLOG = '/blog',
  CLI = '/cli',
  AI = '/ai',
  CONFIRM = '/confirm',
  DISCORD = '/discord',
  DOCS = '/docs',
  DOCS_CLI = '/docs/cli',
  DOCS_DESKTOP = '/docs/desktop',
  DOWNLOAD = '/download',
  HOME = '/',
  JOBS = '/jobs',
  PRIVACY = '/privacy',
  DESKTOP = '/desktop',
  RUN = '/cli/run',
  SUBSCRIBED = '/subscribed',
  TERMS = '/terms',
}

export const PUBLIC_BETA_BADGE_LINK = '/blog/changelog-0017'

export const CLI_INSTALL_URL = `${PAGE_ROUTES.DOCS_CLI}/installation`
export const HOMEPAGE_FULL_URL = 'https://httpie.io'
export const BLOG_FULL_URL = HOMEPAGE_FULL_URL + PAGE_ROUTES.BLOG
export const CHAT_FUL_URL = HOMEPAGE_FULL_URL + PAGE_ROUTES.DISCORD

// Twitter
export const TWITTER_ROOT_URL = 'https://twitter.com'
export const TWITTER_COMPANY_USERNAME = 'httpie'
export const TWITTER_COMPANY_PROFILE_URL = `${TWITTER_ROOT_URL}/${TWITTER_COMPANY_USERNAME}`

// GitHub
export const GITHUB_API_TOKEN = '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'
export const GITHUB_API_BASE_URL = 'https://api.github.com'
export const GITHUB_ORG_NAME = 'httpie'
export const GITHUB_ORG_URL = `https://github.com/${GITHUB_ORG_NAME}`
export const GITHUB_CLI_REPO_NAME = 'cli'
export const GITHUB_CLI_REPO_URL = `${GITHUB_ORG_URL}/${GITHUB_CLI_REPO_NAME}`
export const GITHUB_API_REPO_URL = `${GITHUB_API_BASE_URL}/repos/${GITHUB_ORG_NAME}/${GITHUB_CLI_REPO_NAME}`

// Dev.to
export const DEV_COMMUNITY_COMPANY_PROFILE_URL = 'https://dev.to/pie'

// Redit
export const REDDIT_COMMUNITY_URL = 'https://www.reddit.com/r/HTTPie/'

// Termible
export const TERMIBLE_KEY = 'bc45783070f311eabc550242ac130003'

// Docs
export const HEAD_VERSION = 'HEAD'
