import React from 'react'
import { SWRConfig } from 'swr'

const globalFetcher = (resource: RequestInfo, init?: RequestInit) => fetch(resource, init).then((res) => res.json())

export default function SWRFetcherProvider({ children }: { children: React.ReactNode }) {
  return (
    <SWRConfig
      value={{
        fetcher: globalFetcher,
      }}
    >
      {children}
    </SWRConfig>
  )
}
